var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.message ? _c('div', {
    staticClass: "email-tbl content-container h-100"
  }, [_c('div', {
    staticClass: " p-0"
  }, [_c('div', {
    staticClass: "subject-title mb-3 d-flex"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/blog-dark.svg")
    }
  }), _c('h1', {
    staticClass: "mb-0 ms-3 mt-1 text-capitalize"
  }, [_vm._v(_vm._s(_vm.sentUserLists.emailLog.email_subject))])]), _c('div', {
    staticClass: "email-send-detail"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._m(0), _c('div', {
    staticClass: "notify-section mt-2"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.sentUserLists.emailLog.from_name))]), _c('p', {
    staticClass: "send-to"
  }, [_vm._v("From : " + _vm._s(_vm.sentUserLists.emailLog.from_email))])])]), _c('div', {
    staticClass: "receive-date"
  }, [_vm._v(_vm._s(_vm.sentUserLists.emailLog.sent_date))])]), _c('div', [_c('div', {
    staticClass: "email-template-container",
    staticStyle: {
      "width": "100% !important"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.sentUserLists.contentTemplate)
    }
  })]), _c('div', {
    staticClass: "mt-4"
  }, [_c('h6', [_vm._v("Attachments:")]), _vm.sentUserLists.emailLog.email_log_attachments.length > 0 ? _c('div', {
    staticClass: "attach-file"
  }, [_c('ul', {
    staticStyle: {
      "list-style": "none"
    }
  }, _vm._l(_vm.sentUserLists.emailLog.email_log_attachments, function (file) {
    return _c('li', {
      key: file.id
    }, [_c('a', {
      attrs: {
        "href": file.file_url,
        "target": "__blank",
        "download": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-file"
    }), _vm._v(" " + _vm._s(file.title) + " ")])]);
  }), 0)]) : _c('div', [_vm._v(" Attachment had not been added. ")])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/default_profile.jpg"),
      "alt": "User Picture"
    }
  })]);
}]

export { render, staticRenderFns }