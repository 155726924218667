<template>
  <div  class="nuera-blog-message-container">
    <div class="mail-detail-wrap" v-if="rows.records.length > 0">
      <vue-scroll :ops="msgScroll">
        <div class="mail-detail"
             v-for="(row) in rows.records"
             :key="row.id"
             :class="listClass(row.id)"
             @click="setMessage(row)">
          <i class="fa fa-envelope fa-mail"></i>
          <div class="mail-content">
            <h3 class="text-capitalize">{{row.email_subject}}</h3>
            <p>From: {{row.from_name}}</p>
            <p class="message-list-date">
              <i class="far fa-calendar-alt" aria-hidden="true"></i>
              {{row.sent_date}}
            </p>
          </div>
        </div>
      </vue-scroll>
    </div>
    <div class="text-center" v-else>
      <span class="mt-4"> No result found </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NueraMessageList",
  props: {
    rows: {
      required: true
    },
  },
  data: () => ({
    msgScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 628,
      },
      bar: {
        background: "#ddd",
      },
    },
    currentItemId: null,
  }),
  methods: {
    listClass(id) {
        let activeClass = this.currentItemId == id ? "active-list" : "";
        return `${activeClass} mail-${id}-detail"`;
    },
    setMessage(item) {
      this.$emit("onClickedItem", item);
      this.currentItemId = item.id;
    },
  },
  watch: {
    rows: function (newData) {
      this.currentItemId = newData.records[0].id
    }
  },
}
</script>

<style scoped>
.nuera-blog-message-container .active-list{
  background: #eee;
}
</style>
