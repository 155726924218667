var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nuera-blog-message-container"
  }, [_vm.rows.records.length > 0 ? _c('div', {
    staticClass: "mail-detail-wrap"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.msgScroll
    }
  }, _vm._l(_vm.rows.records, function (row) {
    return _c('div', {
      key: row.id,
      staticClass: "mail-detail",
      class: _vm.listClass(row.id),
      on: {
        "click": function click($event) {
          return _vm.setMessage(row);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-envelope fa-mail"
    }), _c('div', {
      staticClass: "mail-content"
    }, [_c('h3', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(row.email_subject))]), _c('p', [_vm._v("From: " + _vm._s(row.from_name))]), _c('p', {
      staticClass: "message-list-date"
    }, [_c('i', {
      staticClass: "far fa-calendar-alt",
      attrs: {
        "aria-hidden": "true"
      }
    }), _vm._v(" " + _vm._s(row.sent_date) + " ")])])]);
  }), 0)], 1) : _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "mt-4"
  }, [_vm._v(" No result found ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }