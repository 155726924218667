<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-end">
        <div class="page-title"><h1>Neura Blogs</h1></div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="email-tbl h-100">
            <div class="search-wrap mb-3">
              <input class="search-input" v-model="searchQuery" placeholder="Search "/>
              <button @click="searchBlogList"><i class="fa fa-search"></i></button>
            </div>
            <!-- <div class="d-flex"> -->
            <div>
              <nuera-message-list
                  @onClickedItem="getMessage"
                  :rows.sync="rows"
              />
            </div>

            <!-- pagination -->
            <div
                class="d-flex justify-content-between align-items-center p-3 pb-0"
            >
              <div class="mt-3">
                <pagination :rows.sync="rows" :showPageRecord="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8"  v-if="showMessage">
          <nuera-blogs-content
              :message="showMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/resource/Api";
import NueraMessageList from "./partials/NueraMessageList";
import NueraBlogsContent from "./partials/NueraBlogsContent";
export default {
  components: {
    NueraMessageList,
    NueraBlogsContent
  },
  name: "NeuraBlogs",
  data: () => ({
    rows: [],
    showMessage: null,
    searchQuery: null
  }),
  created() {
    this.getAll();
  },
  methods: {
    searchBlogList () {
      let app = this
      app.getAll(app.searchQuery)
    },
    getAll(searchQuery) {
      let url = 'v2/blog/list';
      let loader = this.$loading.show();

      if (searchQuery) {
        const encodedQuery = encodeURIComponent(searchQuery);
        url += `?search=${encodedQuery}`;
      }
      Api.get(url)
          .then((res) => {
            this.rows = res.data;
            this.showMessage = this.rows.records[0];
            if (this.rows.records.length < 1) {
              this.showMessage = null;
            }
          })
          .catch(() => {
            this.rows = [];
          })
          .finally(() => {
            loader.hide();
          });
    },
    getMessage(item) {
      console.log('inside item')
      this.showMessage = []
      this.showMessage = item
    }
  },
  watch: {
    rows: function (newData, oldData) {
      if (newData != oldData) {
        this.showMessage =
            newData.records.length > 0 ? newData.records[0] : null;
      }
    },
    searchQuery(newQuery) {
      if (!newQuery) {
        this.getAll();
      }
    }
  },
};
</script>

<style src="../../assets/css/neura-blog.css">
</style>
